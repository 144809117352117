<template>
  <div class="test vh-100">
    <chart
      ref="chart"
      @range="rangeChangeHandler"
    />
  </div>
</template>

<script>

import Chart from '@/components/Chart'

export default {
  components: {
    Chart,
  },

  data() {
    return {

    }
  },

  methods: {
    async rangeChangeHandler(range) {
      const data = await this.loadData(range)

      this.$refs.chart.clearFigures()
      this.$refs.chart.drawFigures(data)
    },

    async loadData([startDate, endDate], merge = true) {
      const figures = await this.$api.getTestFigures({
        ...this.$refs.chart.brokerData,
        startDate: startDate,
        endDate: endDate,
      }, this.$route.params.method)

      return figures
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
